<template>
  <div>
    <div>
      <SdRadioBlock
        :value="currentShowingProfileType"
        :label="ScheduleTypes.HIGH_AVAILABILITY"
        name="showing-schedule"
        :disabled="isOffMarket || !showingProfile.is_vacant"
        class="position-relative"
        @input="changeCurrentShowingProfileType(ScheduleTypes.HIGH_AVAILABILITY)"
      >
        <h3 class="mt-0 mb-3 line-height-28">
          High availability
        </h3>
        <div>
          <div class="font-weight-bold">
            For optimal prospect conversion rates and quickest leasing cycle ⭐
          </div>
          Prospects can schedule tours even 2 hours in advance, 7 days a week.
          Showdigs agents conduct the tour for you.️
        </div>
        <img
          v-if="isOnMarket && showingProfile.is_vacant"
          src="../../assets/showdigs-exclusive-ribbon.svg"
          class="ribbon"
        >
      </SdRadioBlock>
      <ElAlert
        v-if="isOffMarket || !showingProfile.is_vacant"
        type="warning"
        show-icon
        :closable="false"
        class="mt-3"
      >
        <template v-if="isOffMarket">
          Our agent network is not yet available at this location.
          You can still use Showdigs to manage tours at this property using your in-house staff.
        </template>
        <template v-else>
          Only available for vacant properties.
        </template>
      </ElAlert>
      <SdRadioBlock
        :value="currentShowingProfileType"
        :label="ScheduleTypes.RESTRICTED"
        name="showing-schedule"
        :class="isOffMarket || !showingProfile.is_vacant ? 'mt-3' : 'mt-4'"
        @input="changeCurrentShowingProfileType(ScheduleTypes.RESTRICTED)"
      >
        <h3 class="mt-0 mb-3 line-height-28">
          Restricted
        </h3>
        <div>
          Prospect can schedule only according to a weekly schedule set by you.
        </div>
      </SdRadioBlock>
      <ElAlert
        v-if="mode === 'edit' && uiFlags.showTypeChangeWarning"
        type="warning"
        show-icon
        :closable="false"
        class="my-3"
      >
        Tours that are already scheduled within the updated showing times will be kept, but no changes will be made to the assigned agent.  Tours outside of the updated showing times will be cancelled.
      </ElAlert>
      <SdRadioBlock
        :value="currentShowingProfileType"
        :label="ScheduleTypes.OPEN_HOUSE"
        name="showing-schedule"
        :disabled="!showingProfile.is_vacant"
        :class="isOffMarket || !showingProfile.is_vacant ? 'mt-3' : 'mt-4'"
        @input="changeCurrentShowingProfileType(ScheduleTypes.OPEN_HOUSE)"
      >
        <h3 class="mt-0 mb-3 line-height-28">
          Open house
        </h3>
        <div>
          <p>
            Ideal for units that are likely to lease quickly 🚀
          </p>
          <p>
            Prospects will only see available open house times and can register to attend.
            Have a Showdigs agent conduct the open house for you, or choose to assign a team member.
          </p>
        </div>
      </SdRadioBlock>
      <ElAlert
        v-if="!showingProfile.is_vacant"
        type="warning"
        show-icon
        :closable="false"
        class="mt-3"
      >
        Only available for vacant properties.
      </ElAlert>
    </div>
    <SdLoadingLayer
      :init="init"
    >
      <template
        #loaded
      >
        <ShowingOpenHouseSchedule
          v-if="currentShowingProfileType === ScheduleTypes.OPEN_HOUSE && showingProfile.is_vacant"
          :showing-profile="showingProfile"
          :rules="rules"
          :mode="mode"
          :property="property"
          :weekdays-options="weekdaysOptions"
          :hours-options="hoursOptions"
          :assignees-options="assigneesOptions"
          :market-has-agents="marketHasAgents"
          @update-showing-profile-payload="emitFormStateChanged"
        />
        <template
          v-if="currentShowingProfileType === ScheduleTypes.RESTRICTED"
        >
          <h3 class="font-weight-light font-18 mt-3">
            Weekly showing schedule
          </h3>
          <div>
            Restricted tours assigned to your team members can only be scheduled at
            least {{ business.schedule_notice_limit }} hours in advance. You can change this in your
            <router-link
              :to="{ name: 'app.profile.business-settings' }"
              class="d-inline"
            >
              general settings screen
            </router-link>
          </div>
          <ElAlert
            type="info"
            show-icon
            :closable="false"
            class="mt-3"
          >
            <div class="font-17 font-weight-bold">
              Please note
            </div>
            <ul class="mt-2 mb-0 pl-3">
              <li class="mb-2">
                You can have the same member assigned to at same time to different
                properties - we'll make sure to prevent double-booking at these times.
              </li>
              <li class="mb-2">
                After a tour is scheduled, you will have the option to cancel it, reschedule it, or assign it to a
                different team member
              </li>
              <li v-if="!showingProfile.is_vacant">
                Please make sure to coordinate these showing times with the tenant.
                We'll email them this showing schedule once showings are ready to
                begin, and will notify them 24 hours before every showing window.
              </li>
            </ul>
          </ElAlert>
          <div
            v-if="!showingProfile.is_vacant"
            class="mt-3"
          >
            You can add up to {{ MAX_OCCUPIED_UNIT_SHOWING_WINDOWS }} showing windows,
            up to {{ MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION / 60 }} hours each.
          </div>

          <SdFloatLabel
            v-if="businessScheduleTemplates.length"
            label="Schedule template"
            class="mt-3"
          >
            <ElSelect
              v-model="selectedScheduleTemplate"
              placeholder="Select a schedule template"
              @change="handleScheduleTemplateSelection"
            >
              <ElOption
                v-for="scheduleTemplate in scheduleTemplates"
                :key="scheduleTemplate.id"
                :value="scheduleTemplate.id"
                :label="scheduleTemplate.name"
              />
            </ElSelect>
          </SdFloatLabel>

          <ElTooltip
            v-for="(showingWindow, index) in showingWindows"
            :key="`${index}-${showingWindow.week_day}-${showingWindow.from_hour}-${showingWindow.to_hour}-${showingWindow.assignee_id}`"
            :disabled="!showingWindow.disabled"
            placement="right-start"
            popper-class="popover-panel"
          >
            <template slot="content">
              <i class="sdicon-info-circle" />
              <p>Editing will be available right after the upcoming showing window begins.</p>
            </template>
            <div class="showing-window">
              <div>
                <ElFormItem
                  :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.week_day`"
                  :rules="rules.week_day"
                >
                  <SdFloatLabel label="Day">
                    <ElSelect
                      v-model="showingWindow.week_day"
                      :disabled="showingWindow.disabled"
                      placeholder="Day"
                      @input="emitFormStateChanged"
                    >
                      <ElOption
                        v-for="weekday in weekdaysOptions"
                        :key="weekday.value"
                        :label="weekday.label"
                        :value="weekday.value"
                      />
                    </ElSelect>
                  </SdFloatLabel>
                </ElFormItem>
              </div>
              <div>
                <ElFormItem
                  :ref="`from_hour_${index}`"
                  :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.from_hour`"
                  :rules="rules.from_hour"
                >
                  <SdFloatLabel label="From">
                    <ElSelect
                      v-model="showingWindow.from_hour"
                      class="select-dropdown-height"
                      :disabled="showingWindow.week_day === null || showingWindow.disabled"
                      placeholder="From"
                      @input="emitFormStateChanged"
                    >
                      <ElOption
                        v-for="hourOption in hoursOptions[showingWindow.week_day]"
                        :key="hourOption.value"
                        :label="hourOption.label"
                        :value="hourOption.value"
                      />
                    </ElSelect>
                  </SdFloatLabel>
                </ElFormItem>
              </div>
              <div>
                <ElFormItem
                  :ref="`to_hour_${index}`"
                  :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.to_hour`"
                  :rules="rules.to_hour"
                >
                  <SdFloatLabel label="To">
                    <ElSelect
                      v-model="showingWindow.to_hour"
                      class="select-dropdown-height"
                      :disabled="showingWindow.week_day === null || showingWindow.disabled"
                      placeholder="To"
                      @input="emitFormStateChanged"
                    >
                      <ElOption
                        v-for="hourOption in hoursOptions[showingWindow.week_day]"
                        :key="hourOption.value"
                        :label="hourOption.label"
                        :value="hourOption.value"
                      />
                    </ElSelect>
                  </SdFloatLabel>
                </ElFormItem>
              </div>
              <div>
                <ElFormItem
                  :prop="`${showingProfile.id ? 'showing_profile.' : ''}showing_windows.${index}.assignee_id`"
                  :rules="rules.assignee_id"
                >
                  <SdFloatLabel label="Assignee">
                    <ElSelect
                      v-model="showingWindow.assignee_id"
                      :disabled="showingWindow.disabled"
                      placeholder="Assignee"
                      @input="emitFormStateChanged"
                    >
                      <ElOption
                        v-for="assignee in assigneesOptions"
                        :key="assignee.value"
                        :disabled="assignee.disabled"
                        :label="assignee.label"
                        :value="assignee.value"
                      >
                        {{ assignee.label }}
                        <span
                          v-if="assignee.value !== 'showdigs'"
                          class="float-right"
                        >
                          <i
                            v-if="assignee.isCalendarConnected"
                            class="ml-2 sdicon-calendar-check-o"
                          />
                        </span>
                      </ElOption>
                    </ElSelect>
                  </SdFloatLabel>
                </ElFormItem>
              </div>
              <div
                v-if="showingWindows.length > 1"
                :class="{ 'disabled': showingWindow.disabled }"
                class="delete pointer"
                @click.stop="!showingWindow.disabled ? removeShowingWindow(index) : null"
              >
                <ElTooltip
                  content="Delete"
                  placement="top"
                >
                  <i class="sdicon-trash" />
                </ElTooltip>
              </div>
            </div>
          </ElTooltip>
          <ElButton
            v-if="showingProfile.is_vacant || showingWindows.length < MAX_OCCUPIED_UNIT_SHOWING_WINDOWS"
            size="small"
            class="mt-4"
            @click="addShowingWindow"
          >
            +Add showing window
          </ElButton>
          <template v-if="isOnMarket">
            <div class="d-flex justify-content-between mt-5 mb-3">
              <div class="font-21 line-height-28">
                Showdigs agent backup
              </div>
              <ElSwitch
                v-model="showingProfile.is_agent_backup_enabled"
                class="col-auto d-block"
                :width="44"
              />
            </div>
            <div>
              Whenever your team members are already booked at a certain time,
              still allow prospects to schedule tours with Showdigs agents.
            </div>
          </template>
          <template>
            <div class="d-flex justify-content-between mt-5 mb-3">
              <div class="font-18 line-height-28">
                Back-to-back tours only
              </div>
              <ElSwitch
                v-model="showingProfile.is_only_consecutive_tours"
                class="col-auto d-block"
                :width="44"
              />
            </div>
            <div>
              If you prefer to keep all tours scheduled for the same day back-to-back, to help
              minimize travel to the property. This comes at the expense of prospect flexibility.
            </div>
          </template>
        </template>
      </template>
    </SdLoadingLayer>
  </div>
</template>

<script>
import {
 computed, ref, watch, reactive,
} from '@vue/composition-api';
import {
  getAssigneesOptions,
  getShowdigsAssigneeUser,
  getShowingWindowsFromAvailabilityDays,
  getSortedShowingWindows,
  getWeekdaysOptions,
  shouldDisableShowingWindow,
} from '@/utils/ShowingWindowUtil';
import { ScheduleTypes } from '@/constants/Schedule';
import SdRadioBlock from '@/components/common/form/SdRadioBlock';
import regexConstants from '@/constants/Regex';
import momentUtil from '@/utils/MomentUtil';
import SdLoadingLayer from '@/components/common/SdLoadingLayer';
import {
  MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS,
  MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION,
  MAX_OCCUPIED_UNIT_SHOWING_WINDOWS,
} from '@/constants/UnitShowingProfile';
import get from 'lodash.get';
import ShowingOpenHouseSchedule from '@/components/showing-profile/ShowingOpenHouseSchedule';
import cloneDeep from 'lodash.clonedeep';
import { createEnumValidator } from '@/utils/FormValidationUtil';

export default {
  name: 'ShowingSchedule',
  components: { SdLoadingLayer, SdRadioBlock, ShowingOpenHouseSchedule },
  props: {
    mode: {
      required: true,
      type: String,
      default: 'add',
    },
    showingProfile: {
      required: true,
      type: Object,
    },
    property: {
      required: true,
      type: Object,
    },
    business: {
      required: true,
      type: Object,
    },
    marketHasAgents: {
      required: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    let operatingHours = [];
    const currentShowingProfileType = ref(props.showingProfile?.type || ScheduleTypes.HIGH_AVAILABILITY);

    const scheduleTemplates = ref([]);
    const businessScheduleTemplates = ref([]);
    const selectedScheduleTemplate = ref(props.showingProfile.id ? undefined : null); // 'undefined' is no selection, 'null' is default

    const marketShowingAgentsAvailableRef = ref(true);
    const isRestricted = ref(getIsRestricted());
    const isOpenHouse = computed(computeIsOpenHouse);
    const isOffMarket = computed(computeIsOffMarket);
    const isOnMarket = computed(computeIsOnfMarket);
    const isOccupied = computed(computeIsOccupied);

    const showingWindows = ref([]);
    const weekdaysOptions = ref(getWeekdaysOptions());
    const hoursOptions = ref([]);

    const teamAssignees = ref([]);
    const allAssignees = computed(computeAssignees);
    const allowedAssignees = computed(computeAllowedAssignees);
    const assigneesOptions = computed(computeAssigneeOptions);

    const rules = computed(computeValidationRules);
    const uiFlags = reactive({
      showTypeChangeWarning: false,
    });
    watch(() => props.showingProfile.is_vacant, () => {
      isRestricted.value = getIsRestricted();
      showingWindows.value = getShowingWindows();

      if (isRestricted.value) {
        changeCurrentShowingProfileType(ScheduleTypes.RESTRICTED);
      }
    });

    return {
      init,
      ScheduleTypes,
      currentShowingProfileType,
      isRestricted,
      scheduleTemplates,
      businessScheduleTemplates,
      showingWindows,
      weekdaysOptions,
      hoursOptions,
      uiFlags,
      assigneesOptions,
      allAssignees,
      rules,
      emitFormStateChanged,
      addShowingWindow,
      removeShowingWindow,
      marketShowingAgentsAvailableRef,
      MAX_OCCUPIED_UNIT_SHOWING_WINDOWS,
      MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION,
      changeCurrentShowingProfileType,
      isOpenHouse,
      isOffMarket,
      isOnMarket,
      handleScheduleTemplateSelection,
      selectedScheduleTemplate,
    };

    async function init() {
      marketShowingAgentsAvailableRef.value = get(props.property.market, 'showing_agents_available', false);

      const [operatingHoursData, scheduleTemplateData, assigneesData] = await Promise.all([
        context.root.$store.dispatch('AvailabilityDay/platform'),
        context.root.$store.dispatch('AvailabilityDay/getScheduleTemplates'),
        context.root.$store.dispatch('Auth/getTeamMembers', { paginate: 0 }),
      ]);

      teamAssignees.value = assigneesData;

      operatingHours = operatingHoursData;

      const defaultScheduleTemplate = {
        id: null,
        name: 'Default',
        showing_windows: getShowingWindows(ScheduleTypes.RESTRICTED, true),
      };

      businessScheduleTemplates.value = scheduleTemplateData;

      scheduleTemplates.value = [defaultScheduleTemplate, ...scheduleTemplateData].map((template) => ({
        ...template,
        showing_windows: template.showing_windows.map((showingWindow) => ({
          ...showingWindow,
          assignee_id: showingWindow.assignee_id ?? 'showdigs',
        })),
      }));

      hoursOptions.value = getShowingWindowsFromAvailabilityDays(operatingHours);
      const showingWindows = getShowingWindowsLength() ? props.showingProfile.showing_windows : [];

      if (props.showingProfile.is_open_house) {
        changeCurrentShowingProfileType(ScheduleTypes.OPEN_HOUSE, showingWindows);
      } else if (isRestricted.value) {
        changeCurrentShowingProfileType(ScheduleTypes.RESTRICTED, showingWindows);
      }

      emitFormStateChanged();
      isRestricted.value = getIsRestricted();
    }

    function computeAssigneeOptions() {
      return getAssigneesOptions(allAssignees.value, isOnMarket.value ? [] : ['showdigs']);
    }

    function computeAssignees() {
      return [getShowdigsAssigneeUser('showdigs'), ...teamAssignees.value];
    }

    function computeAllowedAssignees() {
      return allAssignees.value.filter((a) => !isOffMarket.value || a.id !== 'showdigs');
    }

    function getShowingWindowsLength() {
      return props.showingProfile.showing_windows.length;
    }

    function getIsRestricted() {
      if (computeIsOffMarket() || !props.showingProfile.is_vacant) {
        return true;
      }

      return !!getShowingWindowsLength();
    }

    function computeIsOpenHouse() {
      return currentShowingProfileType.value === ScheduleTypes.OPEN_HOUSE;
    }

    function computeIsOnfMarket() {
      return Boolean(props.property.market_id) && marketShowingAgentsAvailableRef.value;
    }

    function computeIsOffMarket() {
      return computeIsOnfMarket() === false;
    }

    function computeIsOccupied() {
      return !props.showingProfile.is_vacant;
    }

    function getShowingWindows(scheduleType = null, forceDefault = false) {
      const type = scheduleType ?? currentShowingProfileType.value;
      if (type === ScheduleTypes.HIGH_AVAILABILITY || props.showingProfile.is_self_showing) {
        return [];
      }
      const showingWindowsLength = getShowingWindowsLength();

      if (!props.showingProfile.is_vacant && (!showingWindowsLength || showingWindowsLength > MAX_OCCUPIED_UNIT_SHOWING_WINDOWS)) {
        return Array.from({ length: MAX_OCCUPIED_UNIT_SHOWING_WINDOWS }, (_) => ({ ...defaultShowingWindow }));
      }

      const listingManagerId = props.showingProfile?.listing_manager_id ?? props.property?.listing_manager_id;
      const assigneeId = isOnMarket.value ? 'showdigs' : listingManagerId;

      if (showingWindowsLength && !forceDefault) {
        const restrictedShowingWindows = [];
        const sortedWindows = getSortedShowingWindows(props.showingProfile.showing_windows);
        sortedWindows.forEach((showingWindow) => {
          const showingWindowData = { ...defaultShowingWindow, ...showingWindow };

          restrictedShowingWindows.push({
            ...showingWindowData,
            disabled: props.mode === 'add' ? false : shouldDisableShowingWindow(showingWindowData, props.property.timezone, props.property.market_id),
            assignee_id: showingWindowData.assignee_id ?? assigneeId,
          });
        });
        return restrictedShowingWindows;
      }

      return operatingHours.map((showingWindow) => ({
        ...defaultShowingWindow,
        ...showingWindow,
        assignee_id: assigneeId,
      }));
    }

    function onScheduleTypeChange() {
      showingWindows.value = getShowingWindows();

      const fields = {
        is_open_house: currentShowingProfileType.value === ScheduleTypes.OPEN_HOUSE,
      };

      if (!isRestricted.value) {
        fields.is_agent_backup_enabled = false;
        fields.is_only_consecutive_tours = false;
      }

      context.emit('update-schedule-type', currentShowingProfileType.value);
      emitFormStateChanged(fields);
    }

    function emitFormStateChanged(componentFields = {}) {
      let params = {
        showing_windows: showingWindows.value,
      };

      if (componentFields.showing_windows) {
        showingWindows.value = componentFields.showing_windows;
      }

      params = { ...params, ...componentFields };

      context.emit('update-showing-profile-payload', params);
    }

    function addShowingWindow() {
      showingWindows.value.push({ ...defaultShowingWindow });
    }

    function removeShowingWindow(index) {
      showingWindows.value.splice(index, 1);
    }

    function handleScheduleTemplateSelection(scheduleTemplateId) {
      const selectedTemplate = scheduleTemplates.value.find((template) => template.id === scheduleTemplateId);

      showingWindows.value = selectedTemplate
          ? getSortedShowingWindows(cloneDeep(selectedTemplate.showing_windows))
          : getShowingWindows();

      emitFormStateChanged();
    }

    function computeValidationRules() {
      return {
        week_day: {
          validator(rule, value, callback) {
            const index = Number(rule.field.match(regexConstants.windowIndex)[1]);
            const showingWindow = showingWindows.value[index];

            if (showingWindow.week_day === null) {
              return callback(new Error('Day is required'));
            }

            if (!weekdaysOptions.value.some((option) => option.value === showingWindow.week_day)) {
              return callback(new Error('Invalid day'));
            }

            if (isOpenHouse.value && showingWindows.value.length > MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS) {
              return callback(new Error(`You can add up to ${MAX_OCCUPIED_UNIT_OPEN_HOUSE_WINDOWS} showing windows`));
            }

            if (isOccupied.value && showingWindows.value.length > MAX_OCCUPIED_UNIT_SHOWING_WINDOWS) {
              return callback(new Error(`You can add up to ${MAX_OCCUPIED_UNIT_SHOWING_WINDOWS} showing windows`));
            }

            return callback();
          },
        },
        unique_week_day: {
          validator(rule, value, callback) {
            const index = Number(rule.field.match(regexConstants.windowIndex)[1]);
            const showingWindow = showingWindows.value[index];

            if (showingWindow.week_day === null) {
              return callback(new Error('Day is required'));
            }

            const sameDayShowingWindows = showingWindows.value
                .filter((innerShowingWindow, innerIndex) => innerShowingWindow.week_day === showingWindow.week_day && innerIndex !== index);

            if (sameDayShowingWindows.length) {
              return callback(new Error('Day already selected'));
            }

            return callback();
          },
        },
        from_hour: {
          validator(rule, value, callback) {
            const windowIndex = Number(rule.field.match(regexConstants.windowIndex)[1]);
            const showingWindow = showingWindows.value[windowIndex];

            if (showingWindow.week_day === null) {
              return callback();
            }

            const allowedOptions = hoursOptions.value[showingWindow.week_day].map((option) => option.value);

            if (!value) {
              return callback(new Error('From is required'));
            }

            if (!allowedOptions.includes(value)) {
              return callback(new Error('Out of operating hours'));
            }

            return callback();
          },
        },
        to_hour: {
          validator(rule, value, callback) {
            const index = Number(rule.field.match(regexConstants.windowIndex)[1]);
            const showingWindow = showingWindows.value[index];

            if (showingWindow.week_day === null) {
              return callback();
            }

            const allowedOptions = hoursOptions.value[showingWindow.week_day].map((option) => option.value);

            if (!value) {
              return callback(new Error('To is required'));
            }

            if (!allowedOptions.includes(value)) {
              return callback(new Error('Out of operating hours'));
            }

            const from = momentUtil(showingWindow.from_hour, null, 'HH:mm');
            const to = momentUtil(showingWindow.to_hour, null, 'HH:mm');
            const durationInMinutes = to.diff(from, 'minutes', true);

            if (durationInMinutes <= 0) {
              return callback(new Error('To must be after From'));
            }

            if (!props.showingProfile.is_vacant && durationInMinutes > MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION) {
              return callback(new Error(`Window can't be longer than ${MAX_OCCUPIED_UNIT_SHOWING_WINDOW_DURATION / 60} hours`));
            }

            const sameDayShowingWindows = showingWindows.value
                .filter((innerShowingWindow, innerIndex) => innerShowingWindow.week_day === showingWindow.week_day && innerIndex !== index)
                .map((innerShowingWindow) => ({
                  from: momentUtil(innerShowingWindow.from_hour, null, 'HH:mm').unix(),
                  to: momentUtil(innerShowingWindow.to_hour, null, 'HH:mm').unix(),
                }));

            if (sameDayShowingWindows.length) {
              const fromTimestamp = from.unix();
              const toTimestamp = to.unix();

              const hasOverlaps = sameDayShowingWindows.some((innerShowingWindow) => {
                if (fromTimestamp >= innerShowingWindow.to) {
                  return false;
                }
                if (toTimestamp <= innerShowingWindow.from) {
                  return false;
                }
                return true;
              });

              if (hasOverlaps) {
                return callback(new Error('This window overlaps with another window'));
              }
            }

            return callback();
          },
        },
        assignee_id: createEnumValidator('Assignee', allowedAssignees.value.map((a) => a.id), 'Assignee not available for this unit'),
      };
    }

    function changeCurrentShowingProfileType(scheduleType, showingWindows = []) {
      if (currentShowingProfileType.value !== scheduleType) {
        uiFlags.showTypeChangeWarning = true;
      }
      currentShowingProfileType.value = scheduleType;
      emitFormStateChanged({ showing_windows: showingWindows });
      onScheduleTypeChange();
    }
  },
};
const defaultShowingWindow = {
  week_day: null,
  from_hour: null,
  to_hour: null,
  assignee_id: null,
  disabled: false,
};
</script>

<style lang="scss" scoped>
.showing-window {
  display: flex;
  align-items: center;

  .select-dropdown-height {
    // Fix for internal bug of the 'ElSelect' component,
    // see @link https://github.com/ElemeFE/element/issues/15818
    ::v-deep {
      .el-input__inner {
        height: 56px !important;
      }
    }
  }

  .disabled {
    color: gray-color('dark');
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  > div:not(:first-of-type) {
    margin-left: 0.75rem;
  }

  > div:not(.delete) {
    flex: 1;
  }
}

.ribbon {
  position: absolute;
  top: -23px;
  right: -23px;
}
</style>
